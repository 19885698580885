import React,{useState,useEffect} from 'react';
import { apiMedia, apiServer } from './Endpoint';
import { isEditable } from '@syncfusion/ej2-react-grids';
import { useNavigate } from 'react-router-dom';
import { Show } from './Alerts';
import { AES, enc } from 'crypto-js';
import { MdDelete } from 'react-icons/md';

export const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];



  const handleFirstMeeting = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"FirstMeeting", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const FirstMeeting = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.FirstMeeting==0?<>
    <p onClick={() =>
       {handleFirstMeeting(props.TransactionId)}} 
       className="text-sm text-red-600">
       Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );

  const handleDeBoard = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"DeBoard", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const DeBoard = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     <p onClick={() =>
       {handleDeBoard(props.TransactionId)}} 
       className="text-sm text-red-600">
       DeBoard
    </p>


  
    </div>
  );


  const handleCompleteStep1 = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"CompleteStep1", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const CompleteStep1 = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.Step1Completed==0?<>
    <p onClick={() =>
       {handleCompleteStep1(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );



  export const step1Grid = [
    { 
      field: 'ProductName',
      headerText: 'Product Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'CustomerName',
      headerText: 'Customer Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Amount',
      headerText: 'Amount',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
      format: 'C2', // Placed format within the correct column
    },
    {
      headerText: 'First Meeting Status',
      width: '60', // Adjusted width for better alignment
      textAlign: 'Center',
      template: FirstMeeting,
    },
    {
        headerText: 'DeBoard',
        width: '40', // Adjusted width for better alignment
        textAlign: 'Center',
        template: DeBoard,
    },

    {
      headerText: 'Step1 Status',
      width: '40', // Adjusted width for better alignment
      textAlign: 'Center',
      template: CompleteStep1,
    },
  ];







  const handleMOUAgreement = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"MOUAgreement", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const MOUAgreement = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.MOUAgreement==0?<>
    <p onClick={() =>
       {handleMOUAgreement(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );

  const handleDomainAndHosting = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"DomainAndHosting", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const DomainAndHosting = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.DomainAndHosting==0?<>
    <p onClick={() =>
       {handleDomainAndHosting(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );

  const handlePaymentCompleted = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"PaymentCompleted", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const PaymentCompleted = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.PaymentCompleted==0?<>
    <p onClick={() =>
       {handlePaymentCompleted(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );


  const handleCompleteStep2 = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"CompleteStep2", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const CompleteStep2 = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.Step2Completed==0?<>
    <p onClick={() =>
       {handleCompleteStep2(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );





  export const step2Grid = [
    { 
      field: 'ProductName',
      headerText: 'Product Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'CustomerName',
      headerText: 'Customer Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Amount',
      headerText: 'Amount',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
      format: 'C2', // Placed format within the correct column
    },

    {
      headerText: 'MOU Status',
      width: '70', // Adjusted width for better alignment
      textAlign: 'Center',
      template: MOUAgreement,
    },

    {
      headerText: 'Domain Status',
      width: '70', // Adjusted width for better alignment
      textAlign: 'Center',
      template: DomainAndHosting,
    },

    {
        headerText: 'Payment Status',
        width: '70', // Adjusted width for better alignment
        textAlign: 'Center',
        template: PaymentCompleted,
      },
  

      {
        headerText: 'DeBoard',
        width: '70', // Adjusted width for better alignment
        textAlign: 'Center',
        template: DeBoard,
    },



    {
      headerText: 'Step2 Status',
      width: '80', // Adjusted width for better alignment
      textAlign: 'Center',
      template: CompleteStep2,
    },

  ];


  const handleSoftwareUpload = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"SoftwareUpload", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const SoftwareUpload = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.SoftwareUpload==0?<>
    <p onClick={() =>
       {handleSoftwareUpload(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );

  const handleThirdPartyServices = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"ThirdPartyServices", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const ThirdPartyServices = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.ThirdPartyServices==0?<>
    <p onClick={() =>
       {handleThirdPartyServices(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );

  const handleTesting = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"Testing", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const Testing = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.Testing==0?<>
    <p onClick={() =>
       {handleTesting(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );

  const handleCompleteStep3 = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"CompleteStep3", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const CompleteStep3 = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.Step3Completed==0?<>
    <p onClick={() =>
       {handleCompleteStep3(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );


  export const step3Grid = [
    { 
      field: 'ProductName',
      headerText: 'Product Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'CustomerName',
      headerText: 'Customer Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Amount',
      headerText: 'Amount',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
      format: 'C2', // Placed format within the correct column
    },

    {
      headerText: 'Upload Status',
      width: '70', // Adjusted width for better alignment
      textAlign: 'Center',
      template: SoftwareUpload,
    },

    {
      headerText: '3rd Party Status',
      width: '70', // Adjusted width for better alignment
      textAlign: 'Center',
      template: ThirdPartyServices,
    },

    {
        headerText: 'Testing Status',
        width: '70', // Adjusted width for better alignment
        textAlign: 'Center',
        template: Testing,
      },
  
      {
        headerText: 'DeBoard',
        width: '70', // Adjusted width for better alignment
        textAlign: 'Center',
        template: DeBoard,
    },


    {
      headerText: 'Step3 Status',
      width: '60', // Adjusted width for better alignment
      textAlign: 'Center',
      template: CompleteStep3,
    },

  ];
  

  const handleUserManual = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"UserManual", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const UserManual = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.UserManual==0?<>
    <p onClick={() =>
       {handleUserManual(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );


  const handleMOUSignature = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"MOUSignature", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const MOUSignature = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.MOUSignature==0?<>
    <p onClick={() =>
       {handleMOUSignature(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );


  const handleCompleteStep4 = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"CompleteStep4", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const CompleteStep4 = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     {
      props.Step4Completed==0?<>
    <p onClick={() =>
       {handleCompleteStep4(props.TransactionId)}} 
       className="text-sm text-red-600">
        Pending
    </p>

      </>:<>
      <p className="text-sm text-green-600">Completed</p>
      </>
    }


  
    </div>
  );





  export const step4Grid = [
    { 
      field: 'ProductName',
      headerText: 'Product Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'CustomerName',
      headerText: 'Customer Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Amount',
      headerText: 'Amount',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
      format: 'C2', // Placed format within the correct column
    },

    {
      headerText: 'User Manual Status',
      width: '70', // Adjusted width for better alignment
      textAlign: 'Center',
      template: UserManual,
    },

    {
      headerText: 'MOU Signature Status',
      width: '70', // Adjusted width for better alignment
      textAlign: 'Center',
      template: MOUSignature,
    },

    {
        headerText: 'DeBoard',
        width: '70', // Adjusted width for better alignment
        textAlign: 'Center',
        template: DeBoard,
    },

    {
      headerText: 'Step4 Status',
      width: '60', // Adjusted width for better alignment
      textAlign: 'Center',
      template: CompleteStep4,
    },

  ];
  
const openLink = (url) =>{
    window.open(url);
}
  
  const JoinMeeting = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     
     <p onClick={() =>
       {openLink(props.Link)}} 
       className="text-sm text-green-600">
        Join Meeting
    </p>


  
    </div>
  );

  const OpenEmail = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     
     <p onClick={() =>
       {openLink(props.Link)}} 
       className="text-sm text-green-600">
        Open Email
    </p>


  
    </div>
  );

  const handleDeleteEmail = async (userId) => {
    Show.showLoading("Processing Data");
    try {
      const encryptedData = sessionStorage.getItem("userDataEnc");
      const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
      const decryptedData = AES.decrypt(encryptedData, encryptionKey);
      const decryptedString = decryptedData.toString(enc.Utf8);
      const userInfo = JSON.parse(decryptedString);
  
      const formData = new FormData();
      formData.append("id", userId);
  
      const response = await fetch(apiServer + "DeleteOneBulkEmail", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      });
  
      // Check if the response is OK
      if (response.ok) {
        // Ensure the response is JSON
        const data = await response.json();
  
        Show.hideLoading();
        Show.Success(data.message);
        window.location.reload();
      } else {
        const errorData = await response.json();
        Show.Attention(errorData.message || "An error occurred");
      }
    } catch (error) {
      Show.Attention("An error occurred: " + error.message);
    }
  };
  


  const DeleteEmail = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     
     <p onClick={() =>
       {handleDeleteEmail(props.id)}} 
       className="text-sm text-red-600">
       <MdDelete size="2rem" />
    </p>


  
    </div>
  );







  export const meetingList = [
    { 
      field: 'Name',
      headerText: 'Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Email',
      headerText: 'Email',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Reason',
      headerText: 'Reason',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
      format: 'C2', // Placed format within the correct column
    },

    {
        field: 'Time',
        headerText: 'Time',
        width: '120', // Adjusted width for better alignment
        textAlign: 'Center',
        format: 'C2', // Placed format within the correct column
      },

    {
      headerText: 'Action',
      width: '70', // Adjusted width for better alignment
      textAlign: 'Center',
      template: JoinMeeting,
    },

   


  ];

  export const officialEmail = [
    { 
      field: 'Section',
      headerText: 'Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Link',
      headerText: 'Link',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },

    {
      headerText: 'Action',
      width: '30', // Adjusted width for better alignment
      textAlign: 'Center',
      template: OpenEmail,
    },

   


  ];

  export const bulkEmailList = [
    { 
      field: 'UserId',
      headerText: 'UserId',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Email',
      headerText: 'Email',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },


    {
      headerText: 'Action',
      width: '20', // Adjusted width for better alignment
      textAlign: 'Center',
      template: DeleteEmail,
    },

   


  ];





  const customDateFormat = (dateString) => {
    const date = new Date(dateString);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true 
    };
    return date.toLocaleString('en-US', options).replace(',',' at');
  };
  

  
  const created_at = (props) => {
    return <span>{customDateFormat(props.created_at)}</span>;
  };

  const updated_at = (props) => {
    return <span>{customDateFormat(props.updated_at)}</span>;
  };
  
  
  

  export const onBoardedGrid = [
    { 
      field: 'ProductName',
      headerText: 'Product Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
        field: 'PricingType',
        headerText: 'PricingType',
        width: '120', // Adjusted width for better alignment
        textAlign: 'Center',
        format: 'C2', // Placed format within the correct column
      },
    {
      field: 'CustomerName',
      headerText: 'Customer Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Amount',
      headerText: 'Amount',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
      format: 'C2', // Placed format within the correct column
    },

    {
        field: 'CustomerEmail',
        headerText: 'Email',
        width: '120', // Adjusted width for better alignment
        textAlign: 'Center',
        format: 'C2', // Placed format within the correct column
      },

      {
        field: 'created_at',
        headerText: 'Started At',
        width: '120',
        textAlign: 'Center',
        template: created_at,
      },
      
      {
        field: 'updated_at',
        headerText: 'Completed At',
        width: '120',
        textAlign: 'Center',
        template: updated_at,
      },

   
  ];


  const handleReOnboard = async (transId) => {

    let userInfo = {}
  
    const encryptedData = sessionStorage.getItem("userDataEnc");
    const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
    const decryptedData = AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedData.toString(enc.Utf8);
    const parsedData = JSON.parse(decryptedString);
  
    userInfo = parsedData; 
  
  
    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("TransactionId", transId) 
    formData.append("AdminId",userInfo.UserId)
  
    
        const response = await fetch(apiServer+"OnBoardFromDeBoard", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          window.location.reload();
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }

  const ReOnboard = (props) => (
    <div className="image flex gap-4 items-center" style={{ width: "100%" }}  >
     

     <p onClick={() =>
       {handleReOnboard(props.TransactionId)}} 
       className="text-sm text-green-600">
        OnBoard
    </p>


  
    </div>
  );


  export const deBoardedGrid = [
    { 
      field: 'ProductName',
      headerText: 'Product Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
        field: 'PricingType',
        headerText: 'PricingType',
        width: '120', // Adjusted width for better alignment
        textAlign: 'Center',
        format: 'C2', // Placed format within the correct column
      },
    {
      field: 'CustomerName',
      headerText: 'Customer Name',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
    },
    {
      field: 'Amount',
      headerText: 'Amount',
      width: '120', // Adjusted width for better alignment
      textAlign: 'Center',
      format: 'C2', // Placed format within the correct column
    },

    {
        field: 'CustomerEmail',
        headerText: 'Email',
        width: '120', // Adjusted width for better alignment
        textAlign: 'Center',
        format: 'C2', // Placed format within the correct column
      },

      {
        field: 'created_at',
        headerText: 'Deborded At',
        width: '120',
        textAlign: 'Center',
        template: created_at,
      },
      
     

      {
        headerText: 'Action',
        width: '60',
        textAlign: 'Center',
        template:  ReOnboard,
      },
   
  ];



